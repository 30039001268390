import objectFitImages from 'object-fit-images';

// [note]
// IE11 can not uderstand object-fit style property. The file for IE11.
// 1. Use object-fit-images node_module.
// 2. Prepare running object-fit-images script (in here).
// 3. Prepare paring stylesheet named object-fit-polyfill-for-ie11.scss
document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementsByClassName('enable-ofi-polyfill').length) {
    objectFitImages(document.querySelectorAll('.enable-ofi-polyfill'));
  }
});
