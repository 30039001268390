import Swiper from 'swiper';

// itinerariy overview Carousel
if (document.querySelector('.cmn-carousel--type_itinerary-overview') !== null) {
  // eslint-disable-next-line no-unused-vars
  const itineraryOverviewCarousel = new Swiper(
    '.cmn-carousel--type_itinerary-overview .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl:
          '.cmn-carousel--type_itinerary-overview .cmn-carousel__pager--type_next',
        prevEl:
          '.cmn-carousel--type_itinerary-overview .cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}

// itinerariy other Carousel
if (document.querySelector('.cmn-carousel--type_itinerary-other') !== null) {
  // eslint-disable-next-line no-unused-vars
  const itineraryOtherCarousel = new Swiper(
    '.cmn-carousel--type_itinerary-other .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl:
          '.cmn-carousel--type_itinerary-other .cmn-carousel__pager--type_next',
        prevEl:
          '.cmn-carousel--type_itinerary-other .cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}
