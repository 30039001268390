import $ from 'jquery';

class Header {
  constructor() {
    this.$spContents = $('.site-header-for-sp-layout-wrap');
    this.$spToggler = $('.site-header-menu-toggler');
    this.$spTogglerIcon = $('.site-header-menu-toggler__icon');
    this.inactiveClassNameOfSpContents = 'd-none';
    this.activeClassName = 'target-active';
    this.inactiveClassNameOfSpTogglerIcon = 'fa-bars';
    this.activeClassNameOfSpTogglerIcon = 'fa-times';
  }

  showSpContents() {
    this.$spContents
      .removeClass(this.inactiveClassNameOfSpContents)
      .hide()
      .fadeIn(500);
  }

  hideSpContents() {
    this.$spContents.fadeOut(500);
  }

  activeTogglerIcon() {
    this.$spTogglerIcon
      .removeClass(this.inactiveClassNameOfSpTogglerIcon)
      .addClass(this.activeClassNameOfSpTogglerIcon);
  }

  inactiveTogglerIcon() {
    this.$spTogglerIcon
      .removeClass(this.activeClassNameOfSpTogglerIcon)
      .addClass(this.inactiveClassNameOfSpTogglerIcon);
  }

  updateGnavState() {
    if (!this.$spToggler.hasClass(this.activeClassName)) {
      this.showSpContents();
      this.activeTogglerIcon();
      this.$spToggler.addClass(this.activeClassName);
    } else {
      this.hideSpContents();
      this.inactiveTogglerIcon();
      this.$spToggler.removeClass(this.activeClassName);
    }
  }
}

if ($('.site-header').length) {
  const siteHeader = new Header();

  siteHeader.$spToggler.on('click', () => {
    siteHeader.updateGnavState();
  });
}
