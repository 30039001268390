import Swiper from 'swiper';

// Recommend Carousel
if (document.querySelector('.cmn-carousel--type_top-recommend') !== null) {
  // eslint-disable-next-line no-unused-vars
  const topRecommendCarousel = new Swiper(
    '.cmn-carousel--type_top-recommend .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl: '.cmn-carousel__pager--type_next',
        prevEl: '.cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}

// Movie Carousel
if (document.querySelector('.cmn-carousel--type_top-movie') !== null) {
  // eslint-disable-next-line no-unused-vars
  const topMovieCarousel = new Swiper(
    '.cmn-carousel--type_top-movie .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl: '.cmn-carousel__pager--type_next',
        prevEl: '.cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}
