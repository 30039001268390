import Swiper from 'swiper';

// Reasons Nature Carousel
if (document.querySelector('.cmn-carousel--type_reasons-nature') !== null) {
  // eslint-disable-next-line no-unused-vars
  const reasonsNatureCarousel = new Swiper(
    '.cmn-carousel--type_reasons-nature .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl:
          '.cmn-carousel--type_reasons-nature .cmn-carousel__pager--type_next',
        prevEl:
          '.cmn-carousel--type_reasons-nature .cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}

// Reasons Culture Carousel
if (document.querySelector('.cmn-carousel--type_reasons-culture') !== null) {
  // eslint-disable-next-line no-unused-vars
  const reasonsCultureCarousel = new Swiper(
    '.cmn-carousel--type_reasons-culture .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl:
          '.cmn-carousel--type_reasons-culture .cmn-carousel__pager--type_next',
        prevEl:
          '.cmn-carousel--type_reasons-culture .cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}

// Reasons Gourmet Carousel
if (document.querySelector('.cmn-carousel--type_reasons-gourmet') !== null) {
  // eslint-disable-next-line no-unused-vars
  const reasonsGourmetCarousel = new Swiper(
    '.cmn-carousel--type_reasons-gourmet .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl:
          '.cmn-carousel--type_reasons-gourmet .cmn-carousel__pager--type_next',
        prevEl:
          '.cmn-carousel--type_reasons-gourmet .cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}

// Reasons Itineraries Carousel
if (
  document.querySelector('.cmn-carousel--type_reasons-itineraries') !== null
) {
  // eslint-disable-next-line no-unused-vars
  const reasonsItinerariesCarousel = new Swiper(
    '.cmn-carousel--type_reasons-itineraries .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl:
          '.cmn-carousel--type_reasons-itineraries .cmn-carousel__pager--type_next',
        prevEl:
          '.cmn-carousel--type_reasons-itineraries .cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}
