import Swiper from 'swiper';
import Slideshow from '../parts/Slideshow';

// Carousel
if (document.querySelector('.cmn-carousel--type_about') !== null) {
  // eslint-disable-next-line no-unused-vars
  const aboutCarousel = new Swiper(
    '.cmn-carousel--type_about .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl: '.cmn-carousel__pager--type_next',
        prevEl: '.cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}

// Slideshow
// if ($('.Page-about').length) { <== [note] I will add unique class to each pages.
const historySlideShow = new Slideshow('js-about-history-slide-show');
const highlightsSlideShow = new Slideshow('js-about-highlights-slide-show');

historySlideShow.init();
highlightsSlideShow.init();
// }
