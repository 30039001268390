import Swiper from 'swiper';
import DominationCkboxesByParentCkbox from '../utilities/DominationCkboxesByParentCkbox';

// 親チェックボックスに子供達チェックボックスが連動する。
if (document.querySelector('.spots-cat-master-ck') !== null) {
  // eslint-disable-next-line no-unused-vars
  const spotsCatMasterCk = new DominationCkboxesByParentCkbox(
    '.spots-cat-master-ck',
  );
}

// Main Picture
if (document.querySelector('.article-slider--type_spots') !== null) {
  // eslint-disable-next-line no-unused-vars
  const topShowMv = new Swiper(
    '.article-slider--type_spots .swiper-container',
    {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 15,
      pagination: {
        el: '.article-slider--type_spots .swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    },
  );
}

// Spots related Carousel
if (document.querySelector('.cmn-carousel--type_spots-related') !== null) {
  // eslint-disable-next-line no-unused-vars
  const spotsRelatedCarousel = new Swiper(
    '.cmn-carousel--type_spots-related .swiper-container',
    {
      slidesPerView: 3,
      spaceBetween: 15,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl: '.cmn-carousel__pager--type_next',
        prevEl: '.cmn-carousel__pager--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    },
  );
}
