import $ from 'jquery';
import scrollTo from '../utilities/ScrollTo'; // [note] ScrollTo serve instance.
import ShowAndHide from '../utilities/ShowAndHide';
// Utilities
// -------------------------
scrollTo();

// Global Parts
// -------------------------
// Add Spot Dialog
window.onload = () => {
  // eslint-disable-next-line no-unused-vars
  const manageAddSpotDialogShowAndHide = new ShowAndHide({
    target: '.add-spot-dialog',
    opener: '.js-add-spot-dialog-open',
    closer: '.js-add-spot-dialog-close',
    hiddenClassName: 'd-none',
  });
};

// Howto Modal
function initHowToModalSelector() {
  const $selectors = $('.howto-modal-pager__icon');
  const $displays = $('.howto-cnt');
  const displayLength = $displays.length;
  let pageIndex = 0;

  $selectors.on('click', event => {
    const $this = $(event.currentTarget);
    const type = $this.data('type');

    if (type === 'next' && pageIndex < displayLength - 1) {
      $displays.hide().removeClass('d-none');
      $selectors.removeClass('disabled');
      $displays.eq(pageIndex + 1).fadeIn(400);
      pageIndex += 1;
      if (pageIndex === displayLength - 1) $this.addClass('disabled');
    }

    if (type === 'prev' && pageIndex !== 0) {
      $displays.hide().removeClass('d-none');
      $selectors.removeClass('disabled');
      $displays.eq(pageIndex - 1).fadeIn(400);
      pageIndex -= 1;
      if (pageIndex === 0) $this.addClass('disabled');
    }
  });
}
initHowToModalSelector();
