// Libs
import 'bootstrap';
// Polyfills
import './polyfills/object-fit-polyfill-for-ie11';
// Layouts
import './layouts/header';
import './layouts/siteBottomInfoBar';
// Pages
import './pages/base';
import './pages/top';
import './pages/reasons';
import './pages/about';
import './pages/spots';
import './pages/topics';
import './pages/itineraries';
