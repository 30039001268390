import $ from 'jquery';

// -------------------------------------
// Slideshow
//
// [html example]
//
// <div class="hoge" data-fade-speed="500" data-interval="1000">
//   <img />
//   <img class="d-none" />
//   <img class="d-none" />
// </div>
//
// * Please add `d-none` class for elms other than 1st elm.
// * data-fade-speed & data-interval are optional.
// -------------------------------------

class Slideshow {
  constructor(wrapClassName) {
    this.$wrap = $(`.${wrapClassName}`);
    this.$slideItems = $(`.${wrapClassName}`).children();
    this.fadeSpeed = $(`.${wrapClassName}`).data('fade-speed') || 1000;
    this.interval = $(`.${wrapClassName}`).data('interval') || 4000;
  }

  run() {
    setInterval(() => {
      this.$wrap
        .children()
        .eq(0)
        .fadeOut(this.fadeSpeed);

      this.$wrap
        .children()
        .eq(1)
        .hide()
        .removeClass('d-none')
        .fadeIn(this.fadeSpeed);

      this.$wrap.append(this.$wrap.children().eq(0));
    }, this.interval);
  }

  init() {
    this.run();
  }
}

export default Slideshow;
