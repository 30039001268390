class DominationCkboxesByParentCkbox {
  constructor(masterElm) {
    this.masterElm = masterElm;
    Array.from(document.querySelectorAll(this.masterElm)).forEach(el => {
      el.addEventListener('click', e => {
        const self = e.currentTarget;
        const groupName = self.getAttribute('name');
        const isChecked = self.checked;
        const cks = document.querySelectorAll(`input[name="${groupName}"]`);
        if (isChecked) {
          cks.forEach(ck => {
            if (ck !== self) {
              ck.checked = true; // eslint-disable-line no-param-reassign
              ck.disabled = true; // eslint-disable-line no-param-reassign
            }
          });
        } else {
          cks.forEach(ck => {
            ck.checked = false; // eslint-disable-line no-param-reassign
            ck.disabled = false; // eslint-disable-line no-param-reassign
          });
        }
      });
    });
  }
}

export default DominationCkboxesByParentCkbox;
