import Swiper from 'swiper';
import DominationCkboxesByParentCkbox from '../utilities/DominationCkboxesByParentCkbox';

// 親チェックボックスに子供達チェックボックスが連動する。
if (document.querySelector('.topics-cat-master-ck') !== null) {
  // eslint-disable-next-line no-unused-vars
  const topicsCatMasterCk = new DominationCkboxesByParentCkbox(
    '.topics-cat-master-ck',
  );
}

// Main Picture
if (document.querySelector('.article-slider--type_topics') !== null) {
  // eslint-disable-next-line no-unused-vars
  const topShowMv = new Swiper(
    '.article-slider--type_topics .swiper-container',
    {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 15,
      pagination: {
        el: '.article-slider--type_topics .swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    },
  );
}
